<template>
  <div class="comment-container min-component">
    <div class="comment-write d-print-none" v-if="isWritable">
      <form @submit.prevent class="kb-form-comment">
        <input type="text" v-model.trim="param.cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." @keyup.enter="addComment" />
        <button class="kb-btn kb-btn-secondary kb-btn-comment" @click.stop="addComment">
          <span class="text">등록</span>
        </button>
      </form>
    </div>

    <!-- Comment List -->
    <div v-if="boardMst.cmntPrntYn == 'Y'" class="comment-wrapper">
      <ul class="comment-list">
        <li v-if="comments.length == 0 && showEmpty" class="comment-item"><p class="comment-delete-text">등록된 댓글이 없습니다.</p></li>
        <template v-for="(comment,idx) in comments" :key="idx">
          <li v-if="comment.stt == '99'" class="comment-item" :class="{'d-none': comment.grpCnt === 0}"><p class="comment-delete-text" :style="{'margin-left': `${comment.lvl*2}rem`}">- 삭제된 댓글입니다 -</p></li>
          <li v-else-if="comment.stt == '11'" class="comment-item" :class="{'d-none': comment.grpCnt === 0}"><p class="comment-delete-text" :style="{'margin-left': `${comment.lvl*2}rem`}">- 운영자에 의해 삭제된 댓글입니다 -</p></li>
          <li v-else class="comment-item">
            <article class="comment" :style="{'margin-left': `${comment.lvl*2}rem`}">
              <div class="comment-avatar">
                <div class="avatar">
                  <ProfileImg :target="comment"/>
                </div>
              </div>
              <div class="comment-contents">
                <div class="content-metadata">
                  <div class="metadata">{{ comment.deptNm }} {{ comment.lrnerNm }}</div>
                  <div class="metadata metadata-date">{{ timestampToDateFormat(comment.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                </div>
                <div class="content-text">
                  <p class="text">{{ comment.cmnt }}</p>
                </div>
              </div>
              <div class="comment-actions d-print-none">
                <div class="dropdown" :class="{ 'is-active' : comment.toggle }">
                  <button class="dropdown-btn kb-btn-actions" @click.stop="comment.toggle=!comment.toggle"><i class="icon-menu"></i></button>
                  <div class="dropdown-target">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list" @click.stop="comment.toggle=!comment.toggle">
                        <li class="dropdown-option-item" v-if="boardMst.cmntDelYn == 'Y' && session.lrnerId == comment.rgtrId">
                          <a class="dropdown-option-link kb-mouse-cursor" @click="deleteComment(idx)"><span class="dropdown-option-text">삭제</span></a>
                        </li>
                        <li class="dropdown-option-item" v-if="isWritable">
                          <a class="dropdown-option-link kb-mouse-cursor" @click="addReply('add', idx)"><span class="dropdown-option-text">댓글 달기</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <div class="comment-write d-print-none" v-if="comment.isReplying">
              <div class="kb-form-comment">
                <input type="text" v-model.trim="replyParam.cmnt" class="kb-form-comment-input" placeholder="댓글 달기 .." @keyup.enter="addReply('save', idx)" />
                <button class="kb-btn kb-btn-secondary kb-btn-comment" @click.stop="addReply('cancel', idx)">
                  <span class="text">취소</span>
                </button>
                <button class="kb-btn kb-btn-secondary kb-btn-comment mx-1" @click.stop="addReply('save', idx)">
                  <span class="text">등록</span>
                </button>
              </div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from 'vuex';
import {useAlert} from "@/assets/js/modules/common/alert";
import {isSuccess, lengthCheck, getItems, timestampToDateFormat, initParams} from "@/assets/js/util";
import ProfileImg from '@/components/common/ProfileImg';
import {
  ACT_DELETE_BOARD_COMMENT,
  ACT_GET_BOARD_COMMENT_LIST,
  ACT_INSERT_BOARD_COMMENT
} from "@/store/modules/board/board-mst";
import {checkWritingLimit, validTarget} from "@/assets/js/modules/board/board-common";

export default {
  name: 'CommonBoardComment',
  props: {
    boardMstMap: Object,
    comPostSn: Number,
    boardSecuKey:String,
    isOper: Boolean,
    showEmpty: Boolean,
  },
  components: {ProfileImg},
  setup(props) {
    const store = useStore();
    const {showMessage, showConfirm} = useAlert();
    const session = computed(() => store.state.auth.session);
    const boardMst = ref(props.boardMstMap);
    const comments = ref([]);
    const isWritable = ref(false);
    const param = ref({
      comPostSn: props.comPostSn,
      boardSecuKey: props.boardSecuKey,
      cmnt: '',
      stt: '00',
    });
    const replyParam = ref({
      comPostSn: 0,
      boardSecuKey: '',
      cmnt: '',
      grpNo: 0,
      lvl: 0,
      ansSeq: 0,
      stt: '00',
    });
    let isSaving = false;

    const getComments = () => {
      comments.value = [];
      store.dispatch(`boardmst/${ACT_GET_BOARD_COMMENT_LIST}`, param.value.boardSecuKey).then((res) => {
        if(lengthCheck(res)){
          comments.value = getItems(res);
        }
      })
    }

    const addComment = () => {
      if(param.value.cmnt == ''){
        showMessage("댓글을 입력하세요");
        return;
      }
      if(!isSaving){
        saveComment(param.value);
      }
    }

    const saveComment = async (params) => {
      isSaving = true;
      if(await checkWritingLimit('cmnt', session.value.lrnerId, boardMst, param.value.boardSecuKey)) {
        store.dispatch(`boardmst/${ACT_INSERT_BOARD_COMMENT}`, params).then((res) => {
          if (isSuccess(res)) {
            getComments();
            showMessage("저장되었습니다.", () => {
              param.value.cmnt = '';
              isSaving = false;
              initParams(replyParam.value);
            });
          }else{
            isSaving = false;
          }
        })
      }else{
        isSaving = false;
      }
    }

    const deleteComment = (idx) => {
      showConfirm("해당 댓글을 삭제하시겠습니까?", () => {
        store.dispatch(`boardmst/${ACT_DELETE_BOARD_COMMENT}`, comments.value[idx].comBoardCmntSn).then((res) => {
          if(isSuccess(res)){
            showMessage("처리되었습니다.", () => {getComments()});
          }
        })
      })
    }

    const addReply = (mode, idx) => {
      if(mode == 'add'){
        comments.value[idx].isReplying = true;
        replyParam.value.comPostSn = comments.value[idx].comPostSn;
        replyParam.value.boardSecuKey = comments.value[idx].boardSecuKey;
        replyParam.value.grpNo = comments.value[idx].grpNo > 0 ? comments.value[idx].grpNo : comments.value[idx].comBoardCmntSn;
        replyParam.value.lvl = comments.value[idx].lvl + 1;
        replyParam.value.ansSeq = comments.value[idx].ansSeq + 1;
        replyParam.value.stt = '00';
      }else if(mode == 'cancel'){
        comments.value[idx].isReplying = false;
        initParams(replyParam.value);
      }else if(mode == 'save'){
        if(replyParam.value.cmnt == ''){
          showMessage("댓글을 입력하세요");
          return;
        }
        saveComment(replyParam.value);
        comments.value[idx].isReplying = false;
      }
    }

    onMounted(() => {
      if(validTarget(boardMst.value.cmntWrtTrgt, props.isOper)){
        isWritable.value = true;
      }
      getComments();
    })

    return {
      param,
      replyParam,
      boardMst,
      comments,
      session,
      isWritable,
      addComment,
      deleteComment,
      addReply,
      timestampToDateFormat,
    }

  }
}
</script>

<style>
.comment-container .comment-delete-text {
  font: var(--kb-body16-fontB);
  color: var(--kb-silver);
}
</style>