<template>
  <div class="view-header min-component view-header-snuggle">
    <h3 class="title">{{post.postTitle}}</h3>
    <p class="subtitle">
      <span class="text" v-if="boardMst.userScrtYn === 'Y'">{{post.userNickNm}}</span>
      <span class="text" v-else >{{post.deptNm}}  {{post.jbgdNm}} {{post.jbpsNm}} {{post.lrnerNm}}</span>
      <span class="text">작성 {{ timestampToDateFormat(post.regDt, 'yyyy.MM.dd hh:mm') }}</span>
      <span class="text" v-if="post.mdfcnDt">수정 {{ timestampToDateFormat(post.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span>
      <span class="text">{{ post.inqCnt }} 조회</span>
    </p>
    <div v-if="customInfos.length > 0" class="mt-3" >
      <div v-for="customInfo in customInfos" :key="customInfo.boardCstmDataSn">
        <p v-if="customInfo.value" class="mb-2"><span class="text-muted me-2">{{customInfo.itemNm}}</span><span>{{customInfo.value}}</span></p>
      </div>
    </div>
  </div>

  <div class="view-body min-component">
    <!-- boardLayoutCd 에 따라 thumbnail 표시-->
    <div class="img-fluid m-3 border-1 border-warning-subtle  " id="kb-common-board" v-if="boardMst.boardLayoutCd=='thumbList' && post.thumbFileUrl">
      <div class="board-thumb-wrapper">
        <div class="board-thumb-card">
         <img class="thumb-image" :src="post.thumbFileUrl"/>
        </div>
      </div>
    </div>
    <!-- 본문 내용 -->
    <div class="editor-area ck-content" v-html="post.postCn"></div>
    <!-- 해시태그 -->
    <div v-if="post.hashTag" class="my-5">
      <template v-for="(tag,idx) in post.hashTag.split(hashTagSeparator)" :key="idx">
        <span v-if="tag" class="text-gold fw-bold me-2">#{{tag}}</span>
      </template>
    </div>
    <!-- 첨부파일 -->
    <div v-if="post.postFileAtchYn === 'Y' && files.length > 0" class="segment-box my-md-5">
      <strong class="text-lg">첨부파일</strong>
      <ul class="mt-lg-2">
        <li v-for="(file, idx) in files" :key="idx">
          <a @click.stop="downloadAtch(file)" class="kb-mouse-cursor">
            <span class="text link-dark">{{file.fileNm}}</span>
            <span class="text text-black-50 ps-md-3">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0) }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {timestampToDateFormat, convertToStorageBytes} from "@/assets/js/util";
import {hashTagSeparator} from "@/assets/js/modules/board/board-common";

export default {
  name: 'BoardViewContent',
  props: {
    boardMst: Object,
    post: Object,
    files: Array,
    customInfos: Array,
    downloadAtch: Function,
  },
  setup() {
    return {
      hashTagSeparator,
      timestampToDateFormat,
      convertToStorageBytes,
    }
  }
}
</script>