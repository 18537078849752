<template>
  <div class="min-component my-5 d-print-none">
    <div class="board-score-container border">
      <div class="board-score-title"><span class="board-score-text fw-bold">평가하기</span></div>
      <div class="board-score-icons">
        <div class="board-score-icon" @click="actStarScore(1)">
          <div class="starScore" :class="{'is-active': starScore >= 1}"></div>
        </div>
        <div class="board-score-icon" @click="actStarScore(2)">
          <div class="starScore" :class="{'is-active': starScore >= 2}"></div>
        </div>
        <div class="board-score-icon" @click="actStarScore(3)">
          <div class="starScore" :class="{'is-active': starScore >= 3}"></div>
        </div>
        <div class="board-score-icon" @click="actStarScore(4)">
          <div class="starScore" :class="{'is-active': starScore >= 4}"></div>
        </div>
        <div class="board-score-icon" @click="actStarScore(5)">
          <div class="starScore" :class="{'is-active': starScore >= 5}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useAlert} from "@/assets/js/modules/common/alert";
import {insertBoardEval} from "@/assets/js/modules/board/board-common";

export default {
  name: 'BoardEvaluation',
  props: {
    comPostSn: Number,
    postStarScore: String,
  },
  setup(props) {
    const starScore = ref(props.postStarScore);
    const {showConfirm, showMessage} = useAlert();
    const actStarScore = (score) => {
      starScore.value = score;
      showConfirm("평가를 제출하시겠습니까?", () => {
        insertBoardEval(props.comPostSn, score, () => {
          showMessage("저장되었습니다.");
        }, () => {
          starScore.value = props.postStarScore;
        })
      }, () => {
        starScore.value = props.postStarScore;
      })
    }

    return {
      starScore,
      actStarScore,
    }
  }}
</script>