import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, reactive, ref} from "vue";
import {notiPfmCds} from "@/assets/js/modules/noti/noti-common";
import {getItems, getPaging, initParams, lengthCheck, setParams} from "@/assets/js/util";
import {ACT_GET_USERS_LIST} from "@/store/modules/auth/auth";
import {ACT_INSERT_BOARD_ACT_LOG} from "@/store/modules/board/board-mst";
import {actTyCdDcds} from "@/assets/js/modules/common/common";

export const boardShareSetup = (props, {emit}) => {
    const store = useStore()
    const {showMessage, showConfirm, showLoading, hideLoading} = useAlert()
    const session = computed(() => store.state.auth.session)
    const title = computed(() => props.boardMst ? props.boardMst.boardTitle + ' 공유' : '게시글 공유')
    const paging = reactive({pageNo: 1, pageSize: 10, totalCount: 0});
    const search = reactive({
        deptNm: '',
        lrnerNm: '',
    })
    const results = ref([]);
    const targets = ref([]);
    const param = reactive({
        message: '',
        notiPfmCds: [],
    })
    const notiPfms = [
        {name: '리브똑똑', dcd: notiPfmCds.liiv}
        , {name: '메신저 쪽지', dcd: notiPfmCds.wiseNet}
        , {name: '내부메일', dcd: notiPfmCds.wiseEml}
    ];
    const maxShareCount = 10;

    const closeModal = () => {
        initParams(param);
        search.deptNm = '';
        search.lrnerNm = '';
        results.value = [];
        targets.value = [];
        paging.totalCount = 0;
        emit('update:modelValue', false);
    }

    const pageFunc = (pageNo) => {
        paging.pageNo = pageNo;
        store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {...search, pageNo: paging.pageNo, pageSize: paging.pageSize}).then(res => {
            if(lengthCheck(res)){
                results.value = getItems(res);
                setParams(paging, getPaging(res));
            }else{
                results.value = [];
                paging.totalCount = 0;
            }
        })
    }

    const clickSearch = () => {
        if(search.deptNm.length < 2 && search.lrnerNm.length < 2){
            showMessage('키워드를 2글자 이상 입력해주세요.');
        }else{
            pageFunc(1);
        }
    }

    const getJbgdCd = (jbgdCd) => {
        return jbgdCd && jbgdCd.length >= 2 ? jbgdCd.substr(0, 2) : '-';
    }

    const checkTargetsLength = (event) => {
        if (event.target.checked && targets.value.length === maxShareCount) {
            showMessage(`최대 ${maxShareCount}명까지 공유할 수 있습니다.`);
            event.preventDefault();
        }
    }

    const selectAll = () => {
        if(targets.value.length >= maxShareCount){
            showMessage(`최대 ${maxShareCount}명까지 공유할 수 있습니다.`);
            return;
        }
        results.value.forEach(result => {
            if(result.lrnerId != session.value.lrnerId
                && targets.value.filter(target => target.lrnerId == result.lrnerId).length == 0
                && targets.value.length < maxShareCount
            ){
                targets.value.push(result);
            }
        })
    }

    const clearAll = () => {
        targets.value = [];
    }

    const clearTarget = (index) => {
        targets.value.splice(index,1);
    }

    const clickShare = () => {
        if(targets.value.length == 0){
            showMessage('공유 대상자를 1명 이상 선택해주세요.');
        }else if(!param.message){
            showMessage('공유 메시지를 입력해주세요.');
        }else if(param.notiPfmCds.length == 0){
            showMessage('공유방법을 1개 이상 선택해주세요.');
        }else{
            showConfirm({title: title.value, text: '게시글을 공유 하시겠습니까?', callback: boardShareAct});
        }
    }

    const boardShareAct = () => {
        showLoading();
        store.dispatch(`boardmst/${ACT_INSERT_BOARD_ACT_LOG}`, {
            actTyCdDcd: actTyCdDcds.ACT_TY_CD_SHARE,
            comPostSn: props.post.comPostSn,
            notiMngDomain: {
                notiTitle: '게시글 공유',
                notiDesc: title.value,
                division: 'ComBoard',
                channelName: 'ehrd',
                rsvtTrsmYn: 'N',
                imgUrl: '/contents/group/SSLBestNoteShare-20230315.jpg',
                moveUrl: `/app/board/${props.post.boardId}/view/${props.post.boardSecuKey}`,
                notiTrgtDomains: targets.value.map(target => {
                    return {
                        trprId: target.lrnerId,
                        trprSn: target.userSn,
                        trprNm: target.lrnerNm,
                        trprTelno: target.mblTelno,
                    }
                }),
                notiMsgDomains: param.notiPfmCds.map(notiPfmCd => {
                    return {
                        notiTitle: title.value,
                        notiPfmCdDcd: notiPfmCd,
                        notiCn: notiPfmCd == notiPfmCds.liiv ? `제목: ${props.post.postTitle}\n\n내용: ${param.message}` : `- 제목: ${props.post.postTitle}<br/><br/>- 내용: ${param.message}`,
                    }
                }),
            },
        }).then(res => {
            if(lengthCheck(res)){
                showMessage('공유 완료 되었습니다.', closeModal);
            }else{
                showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        }).finally(hideLoading);
    }

    return {
        session,
        title,
        paging,
        search,
        results,
        targets,
        param,
        notiPfms,
        closeModal,
        pageFunc,
        clickSearch,
        getJbgdCd,
        checkTargetsLength,
        selectAll,
        clearAll,
        clearTarget,
        clickShare,
    }
}