<template>
  <main class="kb-main" id="kb-board-view" v-if="!isLoading">
    <HotTipHeader v-if="showHottipHeader" />
    <lxp-main-header :show-back="true" class="d-print-none">
      <template v-slot:inner>
        <div class="header-column">
          <!-- 글쓰기 수정/삭제 -->
          <div v-if="((boardMst.postRewrtYn === 'Y' && post.postLvl === 0) || (boardMst.ansRewrtYn === 'Y' && post.postLvl > 0)) && isWriter" >
            <div class="buttons">
              <button class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg" @click="goToEdit(post.boardSecuKey, boardMst.boardId)"><span class="text">수정</span></button>
              <button class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg" @click="clickDelete"><span class="text">삭제</span></button>
            </div>
          </div>
          <!-- 답글 쓰기 -->
          <div v-if="boardMst.ansWrtYn === 'Y' && validTarget(boardMst.ansWrtTrgt, isOper)" >
            <div class="buttons">
              <button class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg" @click="addAnswer"><span class="text">답글쓰기</span></button>
            </div>
          </div>
          <!-- 인쇄 -->
          <div v-if="boardMst.postPrintYn === 'Y'" class="buttons">
            <button class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg" v-print="" ><i class="icon-print opacity-75" /><span class="text">인쇄</span></button>
          </div>
          <!-- 공유 -->
          <div v-if="showHottipHeader" class="buttons">
            <button class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg" @click="toggle.shareModal = !toggle.shareModal"><i class="icon-share opacity-50" /><span class="text">공유</span></button>
          </div>
          <!-- 첨부파일 -->
          <div v-if="post.postFileAtchYn === 'Y'">
            <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
              <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list" v-for="(file,idx) in files" :key="idx">
                    <li class="dropdown-option-item">
                      <a class="dropdown-option-link" :title="file.fileNm" @click="downloadAtch(file)">
                        <span class="dropdown-option-text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                        <span class="download-mb">{{ (file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0)   }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>

    <div class="main-content main-component">
      <div class="board-view-container">
        <BoardViewContent :board-mst="boardMst" :post="post" :custom-infos="customInfos" :files="files" :download-atch="downloadAtch" />

        <div class="board-view-body view-component d-print-none">
          <!-- 좋아요 / 담기 -->
          <div v-if="boardMst.postLikeYn === 'Y' || boardMst.postPutYn === 'Y'" class="like-container">
            <div class="like d-sm-flex flex-sm-column align-items-sm-center">
              <img class="ms-0" src="../../../assets/images/ssl/ic_like.png" alt="">
              <div>
                <button :class="{'is-active': post.isLike === 'Y'}" @click="actLike(post)" v-if="boardMst.postLikeYn === 'Y'">
                  <span>좋아요</span>&nbsp;<span class="like-num">{{post.likeCnt}}</span>
                </button>
                <button :class="{'is-active': post.isPut === 'Y'}" @click="actPut(post)" v-if="boardMst.postPutYn === 'Y'">
                  <span v-if="post.isPut === 'Y'">담기 취소</span>
                  <span v-else>담기</span>
                </button>
              </div>
            </div>
          </div>

          <!-- 이전글 / 다음글 -->
          <div v-if="post.postLvl === 0 && (post.prevPostSnSecuKey || post.nextPostSnSecuKey)" class="position-absolute bottom-0 end-0">
            <button class="kb-btn kb-btn-xs kb-btn-secondary me-2" @click="goToView(post.prevPostSnSecuKey, boardMst.boardId)" :disabled="!post.prevPostSnSecuKey">
              <span class="text">◀ 이전</span>
            </button>
            <button class="kb-btn kb-btn-xs kb-btn-secondary" @click="goToView(post.nextPostSnSecuKey, boardMst.boardId)" :disabled="!post.nextPostSnSecuKey">
              <span class="text">다음 ▶</span>
            </button>
          </div>
        </div>


        <!-- 글 평가 -->
        <board-evaluation v-if="boardMst.postEvalYn === 'Y'" :com-post-sn="post.comPostSn" :post-star-score="post.starScore" />
      </div>

      <!-- 댓글 -->
      <common-board-comment v-if="boardMst.cmntWrtYn === 'Y'" :board-mst-map="boardMst" :board-secu-key="post.boardSecuKey" :com-post-sn="post.comPostSn" :is-oper="isOper" :show-empty="false" />
    </div>
    <BoardShareModal v-model="toggle.shareModal" :boardMst="boardMst" :post="post"/>
  </main>
</template>

<script>
import {boardViewSetup, goToView} from "@/assets/js/modules/board/board-common";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import BoardViewContent from "@/components/board/BoardViewContent.vue";
import CommonBoardComment from "@/components/board/CommonBoardComment";
import BoardEvaluation from "@/components/board/BoardEvaluation";
import HotTipHeader from "@/components/prep/HotTipHeader.vue";
import BoardShareModal from "@/components/board/BoardShareModal.vue";

export default {
  name: 'BoardView',
  methods: {goToView},
  components: {LxpMainHeader, BoardViewContent, CommonBoardComment, BoardEvaluation, HotTipHeader, BoardShareModal,},
  setup: boardViewSetup
}
</script>

