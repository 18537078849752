<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup" id="popup-hrdcolud-h0205">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">{{title}}</h3>
        </header>
        <div class="popup-content">
          <div class="member-search-container">
            <!-- 직원 검색 -->
            <div class="kb-form-fields field-01">
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label"><span class="kb-form-label-text">부점/부서</span></label>
                </div>
                <div class="kb-form-column">
                  <div class="kb-form-search-field">
                    <input v-model.trim="search.deptNm" type="text" class="kb-form-search-input" placeholder="부점 및 부서명 검색" @keyup.enter="clickSearch">
                    <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search"></i></button>
                  </div>
                </div>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label"><span class="kb-form-label-text">직원</span></label>
                </div>
                <div class="kb-form-column">
                  <div class="kb-form-search-field">
                    <input v-model.trim="search.lrnerNm" type="text" class="kb-form-search-input" placeholder="직원명 및 직원번호 검색" @keyup.enter="clickSearch">
                    <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="kb-form-buttons">
              <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSearch"><span class="text">검색</span></button>
            </div>
            <div class="search-results">
              <!-- 검색 결과 -->
              <section class="popup-section">
                <header class="section-header">
                  <h4 class="title">검색결과<strong class="text text-muted ms-2">{{ paging.totalCount }}</strong></h4>
                </header>
                <div class="section-content">
                  <div class="kb-table kb-table-striped member-tablelist">
                    <table>
                      <colgroup>
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: 160px;">
                      </colgroup>
                      <thead>
                        <tr>
                          <th><span class="th-title">지점코드/지점명</span></th>
                          <th><span class="th-title">직원명</span></th>
                          <th><span class="th-title">직원번호</span></th>
                          <th><span class="th-title">직급</span></th>
                          <th><span class="th-title text-primary kb-mouse-cursor" @click.stop="selectAll">전체선택</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="results.length > 0">
                          <tr v-for="(result, index) in results" :key="index">
                            <td><strong class="td-text">{{ result.deptCd }} / {{ result.deptNm }}</strong></td>
                            <td><strong class="td-text">{{ result.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ result.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{getJbgdCd(result.jbgdCd)}}</strong></td>
                            <td>
                              <div class="kb-form-check">
                                <input v-model="targets" type="checkbox" class="kb-form-check-input" :value="result" @click.stop="checkTargetsLength" :disabled="result.lrnerId == session.lrnerId"/>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td colspan="5" class="text-center"><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="pt-5"><CommonPaginationFront :paging="paging" :page-func="pageFunc" /></div>
                </div>
              </section>
              <!-- 공유 대상 -->
              <section class="popup-section">
                <header class="section-header">
                  <h4 class="title">공유대상<strong class="text text-muted ms-2">{{ targets.length }}</strong></h4>
                </header>
                <div class="section-content">
                  <div class="kb-table kb-table-striped member-tablelist">
                    <table>
                      <colgroup>
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: auto;">
                        <col style="width: 160px;">
                      </colgroup>
                      <thead>
                      <tr>
                        <th><span class="th-title">지점코드/지점명</span></th>
                        <th><span class="th-title">직원명</span></th>
                        <th><span class="th-title">직원번호</span></th>
                        <th><span class="th-title">직급</span></th>
                        <th><span class="th-title text-primary kb-mouse-cursor" @click.stop="clearAll">전체해제</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-if="targets.length > 0">
                        <tr v-for="(target, index) in targets" :key="index">
                          <td><strong class="td-text">{{ target.deptCd }} / {{ target.deptNm }}</strong></td>
                          <td><strong class="td-text">{{ target.lrnerNm }}</strong></td>
                          <td><strong class="td-text">{{ target.lrnerId }}</strong></td>
                          <td><strong class="td-text">{{getJbgdCd(target.jbgdCd)}}</strong></td>
                          <td>
                            <div class="kb-form-check">
                              <input type="checkbox" class="kb-form-check-input" :checked="true" @click.prevent.stop="clearTarget(index)">
                            </div>
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="5" class="text-center"><span class="td-text text-muted">공유 대상이 없습니다.</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <!-- 공유 메시지 -->
              <section class="popup-section">
                <header class="section-header"><h4 class="title">공유 메시지</h4></header>
                <div class="section-content">
                  <div class="segment-box">
                    <textarea v-model.trim="param.message" cols="30" rows="6" class="kb-form-control" placeholder="전송할 메시지를 입력해주세요." />
                  </div>
                </div>
              </section>
              <!-- 공유 방법 -->
              <section class="popup-section">
                <header class="section-header"><h4 class="title">공유방법</h4></header>
                <div class="section-content">
                  <div class="kb-form-fields">
                    <div class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">구분</strong>
                        </label>
                      </div>
                      <div class="kb-form-column">
                        <div class="kb-form-group">
                          <div v-for="(notiPfm, index) in notiPfms" :key="index" class="kb-form-check kb-form-check-inline">
                            <input v-model="param.notiPfmCds" type="checkbox" class="kb-form-check-input" :id="`chk_pfm_${index}`" :value="notiPfm.dcd">
                            <label :for="`chk_pfm_${index}`" class="kb-form-check-label"><span class="text">{{notiPfm.name}}</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickShare" :disabled="targets.length == 0"><span class="text">공유하기</span></button>
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import {boardShareSetup} from "@/assets/js/modules/board/board-share-setup";

export default {
  name: 'BoardShareModal',
  components: {CommonPaginationFront},
  props: {
    modelValue: Boolean,
    boardMst: Object,
    post: Object
  },
  emits: ['update:modelValue'],
  setup: boardShareSetup,
}
</script>